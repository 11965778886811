import styled from 'styled-components'
import { NavLink as RouterNavLink } from 'react-router-dom'
import { Row as RowRef } from '../../../components/layouts/Row'
import headerShape from '../../media/img/headerShape.svg'
import { keyframes } from 'styled-components'

export const HeaderContainer = styled.div`
  position: fixed;
  width: 100%;
  display: flex;
  height: 15vh;
  flex-direction: column;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  /* transition: visibility 0.3s ease-in-out, top 0.3s ease-in-out; */
  transition: visibility 0.3s ease-in-out;
  z-index: 10;

  @media screen and (max-width: 768px) {
    height: unset;
  }
`

export const TopHeader = styled.div`
  position: relative;
  background-color: transparent;
  height: 15vh;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ::after {
    content: '';
    display: block;
    height: 0.05px;
    width: 100%;
    /* background-color: #323232; */
    position: absolute;
    left: 0;
    bottom: 0;
  }

  @media screen and (max-width: 768px) {
    height: unset;
  }
`
const animateLight = keyframes`
  0% { 
    transform: translateX(0vw); // Start off-screen to the left
    opacity: 0; 
  }
  10%, 90% { 
    opacity: 1; 
  }
  100% { 
    transform: translateX(105vw); // Move off-screen to the right
    opacity: 0; 
  }

 
`

export const YellowLight = styled.div`
  position: absolute;
  bottom: 0;
  left: 0; // Adjusted to start from the left
  width: 2vw; // Adjust the width as needed
  height: 1px;
  background: yellow;
  box-shadow: 0 0 8px yellow, 0 0 5px yellow, 0 0 10px yellow;
  opacity: 0;
  transition: opacity 2s ease-in-out;

  &.animate {
    animation: ${animateLight} 1.2s ease-out forwards;
  }
`

export const BottomHeader = styled.div`
  background-color: lightgrey;
  height: 5vh;
  display: flex;
  align-items: center;
  padding: 0 4rem;
`

export const HeaderShape = styled.div`
  background-image: url(${headerShape});
  display: flex;
  align-items: center;
  /* justify-content: center; */
  height: 100%;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;

  @media screen and (max-width: 768px) {
    max-width: 80%;
  }
`

export const Hamburger = styled.div`
  cursor: pointer;
  /* display: none; // Initially hidden on desktop */
  margin-right: 4vw;

  @media (max-width: 768px) {
    display: block; // Show only on mobile
  }

  // Add hamburger icon styles here
  div {
    width: 30px;
    height: 3px;
    background-color: ${({ theme }) => theme.colors.primary};
    margin: 6px 0;
    transition: 0.4s;
    box-shadow: 0 0px 4px rgba(0, 0, 0, 0.3);
  }
`

export const Menu = styled.div`
  position: fixed;
  top: 20%;
  /* transform: translateY(-50%); */
  /* left: 0; */
  right: 1rem;
  width: 30vw;
  height: auto;
  background-color: #fafafa;
  background: ${({ theme }) => theme.colors.secondary};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  border-radius: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(120%)')};
  transition: transform 0.3s ease-in-out;

  @media (max-width: 768px) {
    position: fixed; // Make the menu fixed to cover the whole screen
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    /* left: ${({ open }) => (open ? '0' : '-100vw')}; // Slide in from the left */
    transition: left 0.3s ease-in-out;
    display: flex;
    flex-direction: column; // Stack links vertically
    justify-content: center;
    align-items: center;
    display: flex; // Always use flex, but hide offscreen if not open
    transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
    z-index: 1000;
    font-size: 2.3rem;
    border-radius: 0;
  }
`

export const CloseIcon = styled.div`
  cursor: pointer;
  position: absolute;
  top: 3rem;
  right: 4rem;
  font-size: 2.6rem;

  @media (max-width: 768px) {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }
`

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 2rem 0;
  display: flex;
  flex-direction: row;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 5rem; */
  /* height: 100%; */

  > li {
    margin: 0.7rem 0;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    > li {
      margin: 1rem 0;
    }

    margin-bottom: 3rem;
  }
`

export const NavLink = styled(RouterNavLink)`
  text-decoration: none;
  color: black;
  padding: 0 3rem;
  font-size: 2rem;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }

  // Add more styles as needed

  @media screen and (max-width: 768px) {
    padding: 1rem 0;
    font-size: 2.2rem;
  }
`

export const Logo = styled.img`
  width: ${({ width }) => width || 'fit-content'};
  padding: 1rem;
  height: ${({ height }) => height || 'auto'};

  width: ${({ width }) => width || '200px'};
  /* height: ${({ height }) => height || 'auto'}; */
  /* max-width: 550px; // Prevents the logo from growing too large */
  /* max-height: 150px; // Prevents excessive height scaling */
  margin-left: 20px;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: 200px;
    padding: 1rem;
  }
`

export const LogoLong = styled.img`
  width: ${({ width }) => width || 'auto'};
  max-width: 130px;
  margin-top: 0rem;
  margin-bottom: 3rem;
  cursor: pointer;
  /* display: none; */

  @media screen and (max-width: 768px) {
    max-width: 150px;
    display: flex;
  }
`

export const CTAButton = styled.button`
  background: ${({ theme }) => theme.colors.primary};
  color: white;
  padding: 1rem 2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  &:hover {
    background: white;
    color: ${({ theme }) => theme.colors.primary};
  }
`

export const Row = styled(RowRef)`
  /* justify-content: flex-end; */
  width: fit-content;
  margin: 0;
  margin-left: auto;
`
